import { Button, TabList } from '@fluentui/react-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { LogoWithText } from '@/features/layout/components/Logo';

export const StyledLogoWithText = styled(LogoWithText)`
  min-height: 70px;
`;

export const StyledTabList = styled(TabList)<{ $isCenter?: boolean }>`
  min-height: 70px;
  display: flex;
  align-self: stretch;
  flex-grow: 1;

  ${(props) => props.$isCenter && `justify-content: center;`}
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: stretch;
  text-decoration: none;
`;

export const LogOutButton = styled(Button)`
  margin: 20px 0;
`;
