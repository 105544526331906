import {
  AvatarGroup,
  AvatarGroupItem,
  Body1,
  Body1Strong,
  Button,
  CounterBadge,
  PresenceBadge,
  Subtitle2Stronger,
  Theme,
  Tooltip,
} from '@fluentui/react-components';
import { observer } from 'mobx-react-lite';
import { FC, ReactElement, useMemo } from 'react';
import styled from 'styled-components';

import { useStore } from '@/stores';

import { FreeDateMeeting } from '../api/meetings';
import { AnimatedButtonVariant, BaseAnimatedWrapper } from '../helpers/motions';
import Description from './Description';
import Location from './Location';
import { sortParticipantsInActionOrder } from './MeetingCard/utils';
import MeetingInfo from './MeetingInfo';
import NameMailto from './NameMailto';
import Slot from './Slot';
import SlotWrapper from './SlotWrapper';
import TimeSlotsWrapper from './TimeSlotsWrapper';

const Container = styled.div<{ $theme: Theme; $themeMode: string }>`
  margin-top: 15px;
  background-color: ${(props) => (props.$themeMode === 'light' ? '#fff' : props.$theme.colorNeutralBackground1)};
  padding: 30px;
  box-shadow: 0px 3px 15px rgba(20, 67, 114, 0.08);
  border-radius: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
`;

const SlotListWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Label = styled.span`
  display: block;
  color: #8f9aa9;
  font-size: 14px;
  white-space: pre;
`;

const AvatarGroupWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
`;

const StyledAvatarGroup = styled(AvatarGroup)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const ToWrapper = styled.div`
  display: flex;
  margin-bottom: -15px;
  margin-top: 15px;
`;

const SelectAllSlotsButton = styled(Button)`
  display: flex;
  align-self: end;
`;

export interface ChoiceObject {
  time: string;
  date: string;
  id: string;
  isDisabled?: boolean;
}

interface TimeSlotsProps {
  event: FreeDateMeeting;
  choicesList: { date: string; slots: ChoiceObject[] }[];
  selectedSlotIds: string[];
  timeZone: string;
  setSelectedSlotIds: (ids: string[]) => void;
  isDisabledToInteract: boolean;
  voterEmail?: string;
  externalCalendarSource?: 'microsoft' | 'google';
  userEventsMatchingSlotIds?: string[];
  selectAllSlots: () => void;
  unselectAllSlots: () => void;
}

const TimeSlots: FC<TimeSlotsProps> = (props): ReactElement => {
  const { appStore } = useStore();

  const isAllSlotsSelected = useMemo(() => {
    const totalEnabledSlots = props.choicesList
      .flatMap((choice) => choice.slots ?? [])
      .filter((slot) => !slot.isDisabled && !props.userEventsMatchingSlotIds?.includes(slot.id)).length;

    return props.selectedSlotIds.length >= totalEnabledSlots;
  }, [props.choicesList, props.selectedSlotIds]);

  const toggleTime = (id: string) => {
    const existingSlotId = props.selectedSlotIds.find((selectedSlotId) => selectedSlotId === id);
    if (existingSlotId) {
      const newSelectedSlotIds = props.selectedSlotIds.filter((selectedSlotId) => selectedSlotId !== existingSlotId);
      props.setSelectedSlotIds(newSelectedSlotIds);
    } else {
      props.setSelectedSlotIds([...props.selectedSlotIds, id]);
    }
  };

  const toggleDay = (date: string) => {
    const existingDay = props.choicesList.find((item) => item.date === date);
    if (!existingDay) return;
    const idsOfDay = existingDay.slots.filter((item) => !item.isDisabled).map((item) => item.id);

    const idsToSelect = idsOfDay.filter((item) => !props.selectedSlotIds.includes(item));

    if (idsToSelect.length === 0) {
      const newSelectedSlotIds = props.selectedSlotIds.filter((item) => !idsOfDay.includes(item));
      props.setSelectedSlotIds(newSelectedSlotIds);
    } else {
      props.setSelectedSlotIds([...props.selectedSlotIds, ...idsToSelect]);
    }
  };

  return (
    <Container $theme={appStore.microsoftStore.theme} $themeMode={appStore.themeMode}>
      <Subtitle2Stronger>{props.event.title}</Subtitle2Stronger>
      {props.event.meetingDescription && <Description description={props.event.meetingDescription} />}
      <Location
        location={[
          ...(props.event.location ? [props.event.location.split('\n').join('<br>')] : []),
          ...(props.event.isTeamsMeeting ? ['Microsoft Teams Meeting'] : []),
        ].join('; ')}
      />
      <ToWrapper>
        <Label>{`To: `}</Label>
        <Body1>{props.voterEmail}</Body1>
      </ToWrapper>
      <Wrapper>
        <Label>{`Organized by `}</Label>
        <NameMailto creatorName={props.event.creatorDisplayName} email={props.event.creator} />
      </Wrapper>
      <AvatarGroupWrapper>
        <Body1Strong>{`Participants: `}</Body1Strong>
        <StyledAvatarGroup layout="stack" style={{ gap: '15px' }}>
          {sortParticipantsInActionOrder(props.event.participants, props.event.votingLinks, props.event.votes).map(
            (item, index) => (
              <Tooltip content={item} relationship="label" key={index}>
                <AvatarGroupItem
                  badge={{
                    style: { right: '-5px', bottom: '-5px' },
                    icon: props.event.votes.map((vote) => vote.participant).includes(item) ? (
                      <CounterBadge
                        size="small"
                        overflowCount={999}
                        count={props.event.votes.filter((obj) => obj.participant === item)[0]?.slotIds.length}
                      />
                    ) : (
                      <PresenceBadge status="unknown" />
                    ),
                  }}
                  color={props.event.votes.map((vote) => vote.participant).includes(item) ? 'colorful' : 'neutral'}
                  active={props.event.votingLinks.find((obj) => obj.participant === item)?.visited ? 'active' : 'unset'}
                />
              </Tooltip>
            ),
          )}
        </StyledAvatarGroup>
      </AvatarGroupWrapper>
      <SlotListWrapper>
        <MeetingInfo interval={props.event.interval} timeZone={props.timeZone} />
        <SelectAllSlotsButton
          appearance="primary"
          onClick={isAllSlotsSelected ? props.unselectAllSlots : props.selectAllSlots}
        >
          {isAllSlotsSelected ? 'Unselect all slots' : 'Select all slots'}
        </SelectAllSlotsButton>
        {props.choicesList.map((item, index) => (
          <SlotWrapper key={index} data-testid="voting-slot-group">
            <BaseAnimatedWrapper
              whileHover={AnimatedButtonVariant.actions.whileHover}
              whileTap={AnimatedButtonVariant.actions.whileTap}
            >
              <Slot
                time={item.date}
                asDay
                id={item.date}
                toggleSlot={() => toggleDay(item.date)}
                isDisabled={item.slots.filter((slot) => !slot.isDisabled).length === 0}
                isDisabledToInteract={props.isDisabledToInteract}
              />
            </BaseAnimatedWrapper>
            <TimeSlotsWrapper>
              {item.slots.map((item, index) => (
                <BaseAnimatedWrapper
                  whileHover={AnimatedButtonVariant.actions.whileHover}
                  whileTap={AnimatedButtonVariant.actions.whileTap}
                >
                  <Slot
                    key={index}
                    data-testid="voting-slot"
                    time={item.time}
                    id={item.id}
                    isOccupiedByCalendar={
                      props.userEventsMatchingSlotIds?.find((slot) => slot === item.id)
                        ? props.externalCalendarSource
                        : undefined
                    }
                    selected={!!props.selectedSlotIds.find((slot) => slot === item.id)}
                    isDisabledToInteract={props.isDisabledToInteract}
                    isDisabled={item.isDisabled}
                    toggleSlot={toggleTime}
                  />
                </BaseAnimatedWrapper>
              ))}
            </TimeSlotsWrapper>
          </SlotWrapper>
        ))}
      </SlotListWrapper>
    </Container>
  );
};

export default observer(TimeSlots);
