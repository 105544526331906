import { Spinner, Tab } from '@fluentui/react-components';
import { SignOutRegular } from '@fluentui/react-icons';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useViewSize } from '@/hooks/useViewSize';
import { useStore } from '@/stores';
import { CommonProps } from '@/utils/props';

import { Paper } from '../Paper';
import { Stack } from '../Stack';
import { LogOutButton, StyledLink, StyledLogoWithText, StyledTabList } from './styles';

export type NavigationBarProps = CommonProps<{ children?: never }>;

export const NavigationBar: FC<NavigationBarProps> = observer(() => {
  const { appStore } = useStore();
  const location = useLocation();
  const viewSize = useViewSize();

  const isCreateMeetingPage = location.pathname.startsWith('/create-meeting');
  const isMyMeetingsPage = location.pathname.startsWith('/my-meetings');
  const isAboutPage = location.pathname.startsWith('/about');
  const tabValue = isCreateMeetingPage
    ? 'create-meeting'
    : isMyMeetingsPage
      ? 'my-meetings'
      : isAboutPage
        ? 'about'
        : undefined;

  const handleSignOutClick = useCallback(() => {
    appStore.authStore.signOut();
  }, []);

  const logoutButton = (
    <LogOutButton
      icon={appStore.authStore.loading.is('signOut') ? <Spinner size="tiny" /> : <SignOutRegular />}
      onClick={handleSignOutClick}
    >
      Sign Out
    </LogOutButton>
  );

  return (
    <Paper padding={[0, 3]} direction={viewSize.isMobile ? 'down' : 'end'} spacing={viewSize.isMobile ? 0 : 2}>
      <Stack justifyContent="spaceBetween">
        <StyledLogoWithText />
        {viewSize.isMobile && logoutButton}
      </Stack>
      <StyledTabList selectedValue={tabValue} $isCenter={viewSize.isMobile}>
        <StyledLink to="/create-meeting">
          <Tab value="create-meeting">Create Meeting</Tab>
        </StyledLink>
        <StyledLink to="/my-meetings">
          <Tab value="my-meetings">My Meetings</Tab>
        </StyledLink>
        <StyledLink to="/about">
          <Tab value="about">About</Tab>
        </StyledLink>
      </StyledTabList>
      {!viewSize.isMobile && logoutButton}
    </Paper>
  );
});
